@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "bootswatch";

.glowing{
	text-shadow: 0 0 3px #fff, 0 0 9px #fff;
}

.navbar-link{
	font-size: 2rem;
	text-decoration: none;
	color: $white;
	&:hover{
		color: $white;
	}
	&:focus{
		color: $white;
	}
	&:active{
		color: $white;
	}
	&:visited{
		color: $white;
	}

	&.active{
		@extend .glowing;
	}


}
.navbar-container {
	background-color: rgba(0,0,0,0.5);
}

/*
.generic-container {
background-color: rgba(0,0,0,0.7);
}
 */
.shadowed{
	background-color: rgba(0,0,0,0.5);
}


body, html {
	height: 100%;
}
#root{
	height: 100%;
}

* {
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
	scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar { 
	display: none;  /* Safari and Chrome */
}

h5 a {
	color: $white;
	text-decoration: underline;

	&:hover{
		color: $white;
		text-decoration: underline;
	}
	&:visited{
		color: $white;
		text-decoration: underline;
	}
}

/* make img-fluid on sm or lower */

